
import '../css/style.css';
import carosal1 from '../img/carousel-1.jpg';
import carosal2 from '../img/carousel-2.jpg';
import carosal3 from '../img/carousel-3.jpg';
import offer1 from '../img/offer-1.jpg';
import offer2 from '../img/offer-2.jpg';
import mens from '../img/mens.jpg';
import mens1 from '../img/mens2.jpg';
import mens2 from '../img/mens4.webp';
import mens5 from '../img/mens5.avif';
import mensmodel from '../img/imgs/menmodel.png'
// import carosaltshirt from '../img/carosaltshirt.webp';
import tshirtcarosal from '../img/tshirtcarosal.webp';
import tshirtbg from '../img/tshirtlogin.jpeg'
import { Link } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import Tshirtvideo from '../img/tshirtvideo.mp4';
import kidsbanner from '../img/imgs/kidsbanner.jpg'
import tsirtbanner from '../img/imgs/tshirtbanner1.webp'
import tshirtbanner1 from '../img/imgs/tshirtbanner.avif'
import leadytshirt from '../img/ladystshirt.jpg'
import Kidbanner from '../img/kid.jpg'
import offer from '../img/imgs/offer.webp'
import offertshirt from '../img/imgs/offertshirt.avif'
import model1 from '../img/model1.jpeg'
import model2 from '../img/model2.jpeg'
import model3 from '../img/model3.jpeg'


function Banner()
{
    const [isPlaying, setIsPlaying] = useState(false);
    const videoRef = useRef(null);
  
    const togglePlayPause = () => {
      const video = videoRef.current;
      if (video.paused) {
        video.play();
        setIsPlaying(true);
      } else {
        video.pause();
        setIsPlaying(false);
      }
    };
  
    const handleVideoPlay = () => {
      setIsPlaying(true);
    };
  
    const handleVideoPause = () => {
      setIsPlaying(false);
    };
  
    return(
        <div className="container-fluid mb-3">
          


{/* <div 
 style={{height:'1000px',backgroundColor:'red',fontSize:'36px'}}
>
Scroll Up and Down this page to see the parallax scrolling effect.
This div is just here to enable scrolling.
Tip: Try to remove the background-attachment property to remove the scrolling effect.
</div> */}
        <div className="row px-xl-5">


            <div className="col-lg-8">
              
                <div id="header-carousel" className="carousel slide carousel-fade mb-30 mb-lg-0" data-ride="carousel">
                    <ol className="carousel-indicators">
                        <li data-target="#header-carousel" data-slide-to="0" className="active"></li>
                        <li data-target="#header-carousel" data-slide-to="1"></li>
                        <li data-target="#header-carousel" data-slide-to="2"></li>
                    </ol>
                    <div className="carousel-inner">

                        <div className="carousel-item position-relative active img-div" >
{/* <img className='img-design' src={mensmodel}></img> */}
                            <img className="position-absolute w-100 h-100 img-design" src={model1} style={{objectFit:'cover'}}/>
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown h1-banner" >Men Fashion</h1>
                                    <p className="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                    {/* <Link className="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" href="#">Shop Now</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item position-relative img-div" >
                            <img className="position-absolute w-100 h-100" src={model2 } style={{objectFit:'cover'}}/>
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Women Fashion</h1>
                                    <p className="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                    {/* <Link className="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" href="#">Shop Now</Link> */}
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item position-relative img-div">
                            <img className="position-absolute w-100 h-100" src={ Kidbanner} style={{objectFit:'cover'}}/>
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{maxWidth: '700px'}}>
                                    <h1 className="display-4 text-white mb-3 animate__animated animate__fadeInDown">Kids Fashion</h1>
                                    <p className="mx-md-5 px-5 animate__animated animate__bounceIn">Lorem rebum magna amet lorem magna erat diam stet. Sadips duo stet amet amet ndiam elitr ipsum diam</p>
                                    {/* <Link className="btn btn-outline-light py-2 px-4 mt-3 animate__animated animate__fadeInUp" href="#">Shop Now</Link> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            
            <div className="col-lg-4">
                <div className="product-offer mb-30" style={{height: '200px'}}>
                <video
        ref={videoRef}
        className='tshirtvideo'
        muted
        onClick={togglePlayPause}
        onPlay={handleVideoPlay}
        onPause={handleVideoPause}
        style={{ width: '100%', height: '100%' ,objectFit:'cover'}}
      >
        <source src={Tshirtvideo} type="video/mp4" />
      </video>
      {!isPlaying && (
        <i className="bi bi-play-fill i-btn-style" 
       
          onClick={togglePlayPause}
         
        >
     
        </i>
      )}

                </div>
                <div className="product-offer mb-30"  style={{height: '200px',border:'5px solid #ffc107'}}>
                    <img className="img-fluid" src={offertshirt} alt=""/>
                    <div className="offer-text">
                        <h6 className="text-white text-uppercase">Save 20%</h6>
                        <h3 className="text-white mb-3">Special Offer</h3>
                        <Link to='/Offerproduct' className="btn btn-primary">Shop Now</Link>
                    </div>
                </div>
            </div>
        </div>

  

    </div>
    )
}
export default Banner;