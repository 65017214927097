
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Index from "./Home";
import Header from "./component/Header";
import Subheader from "./component/Subheader";
import Banner from "./Home/Banner";
import Card from "./Home/Cards";
import Categories from "./Home/Catogries";
import Product from "./Home/Product";
import Offer from "./Home/Offer";
import Recentproduct from "./Home/Recentproduct";
// import Vendor from "./Home/Vendor";
import './css/Product.css';
import Footer from "./component/Footer";
import Backtop from "./component/Backtop";
import Contact from "./component/Contact";
import Filter from "./Filter/Filterprd";
import Productdetail from "./Filter/Productdetail";
import Addtocart from "./Filter/Addtocart";
import Orderplace from "./Filter/Orderplace";
import Productcard from "./component/Priductcard";
import Preloader from "./component/Preloader";
import { useEffect, useState } from "react";
import Herhim from "./Home/herhim.js";
import Kids from "./Home/Kids.js";
import Kidproduct from "./Home/Kidproduct.js";
// import Index from "./Home/";
import About from "./component/About";
import ScrollToTopOnRouteChange from "./component/Scrolltop.js";
import Scrollcatogries from "./component/Scrollcatogries.js";
import Header1 from "./component/Header1.js";
import Search from "./component/Search.js";
import Visiting from "./Filter/Visiting.js";
import Icons from "./component/Icons.js";
import Offerproduct from "./component/Offerproduct.js";
import './App.css';
// admin

import './VENDER/Vendorasset/Vendorcss/vencss.css';
// import Vendorhome from "./VENDER/Vendorhome/Vendorhome";
// import Vendorslid from "./VENDER/Vendorhome/Vendorslid";
// import Adminsidebar from "./VENDER/Vendorhome/Adminsidebar";
import Admin from "./VENDER/Vendorhome/Adminsidebar";
import './VENDER/Vendorhome/Vendorcard.css';
import Vendorcards from "./VENDER/Vendorhome/Vendorcardshome";
import Vendorfilter from "./VENDER/Vendorfilter/vendorfilterpage.js";
import Vendorproductdetails from "./VENDER/VendorPrdDetails/Vendorproductdetails.js";
import Prdorder from "./VENDER/Productorder/Prdorder.js";
import Vendorcarts from "./VENDER/Vendorcarts/Vendorcarts.js";
import Vendorlogin from "./VENDER/Vendcomponent/Vendorlogin.js";
import Vendorcardshome from "./VENDER/Vendorhome/Vendorcardshome";
import Vedorsnavbar from "./VENDER/Vendcomponent/Vendsidebar.js";
// import Navbar from "Navbar";
import Dashboard from "./VENDER/Dashboard.js";
import Chart from "./VENDER/Chart.js";
import ScrollToTop from "./component/Scrolltop.js";
// import { Navigate } from "react-router-dom";

function App() {
  const [navVisible, showNavbar] = useState(false);
  const [screenLoading, setScreenLoading] = useState(false);

  useEffect(() => {
    setScreenLoading(true);
    setTimeout(() => {
      setScreenLoading(false);
    }, 1000);
  }, []);


  return (
    <>
      {screenLoading ? (
        <Preloader />
      ) : (
        <BrowserRouter>
          <Routes>
            <Route path="/preoloader" element={<Preloader />}></Route>

            <Route path="/" element={<>
              {/* <Header /> */}
              <Header1 />
              {/* <Search/> */}
              {/* <Subheader /> */}
              {/* <Scrollcatogries /> */}
              <Banner />
              <Categories />
              <Offer />
              <Recentproduct />
              <Herhim />
              <Product />
              <Kids />
              <Kidproduct />
              <Footer />
              <ScrollToTop />
            
            </>}></Route>

            <Route path="/Offerproduct" element={
              <>
                <Header1 />
                {/* <Scrollcatogries /> */}
               <Offerproduct/>
               <Footer />
              </>
            }>
            </Route>

            <Route path="/Visiting" element={
              <>
                <Header1 />
                {/* <Scrollcatogries /> */}
               <Visiting/>
               <Footer />
              </>
            }>
            </Route>

            <Route path="/contact" element={
              <>
                {/* <Header />
                <Subheader /> */}
                <Header1 />
                <Contact />
                <Footer />
                <Backtop />
                <ScrollToTop />
              </>
            }>

            </Route>
            <Route path="/filter" element={<>
              {/* <Header />
              <Subheader /> */}
              <Header1 />
              <Filter />
              <Footer />
              <ScrollToTop />
            </>}></Route>

            <Route path="/Productdetail" element={<>
              {/* <Header />
              <Subheader /> */}
              <Header1 />
              {/* <Scrollcatogries /> */}
              <Productdetail />
              <Footer />
              <ScrollToTop />
            </>}></Route>

            <Route path="/addtocard" element={<>
              {/* <Header />
              <Subheader /> */}
              <Header1 />
              {/* <Scrollcatogries /> */}
              <Addtocart />
              <ScrollToTop />
              <Footer />
            </>}></Route>

            <Route path="/Orderplace" element={<>
              {/* <Header />
              <Subheader /> */}
              <Header1 />
              {/* <Scrollcatogries /> */}
              <Orderplace />
              <Footer />
              <ScrollToTop />
            </>}></Route>
          </Routes>



          {/* Admine */}

          <Routes >
            <Route path="/admin" element={<>
              {/* <Vendorhome/> */}
              {/* <Vendorslid/> */}
              <Vendorcards />
              <Admin />
            </>}></Route>
          </Routes>

          <Routes >
            <Route path="/Vendorfilter" element={<>

              <Vendorfilter />

            </>}></Route>
          </Routes>
          <Routes >
            <Route path="/Vendorproductdetails" element={<>
              <Vendorproductdetails />
            </>}></Route>
          </Routes>
          <Routes >
            <Route path="/Prdorder" element={<>
              <Prdorder />
            </>}></Route>
          </Routes>
          <Routes >
            <Route path="/Vendorcarts" element={<>
              <Vendorcarts />
            </>}></Route>
          </Routes>
          <Routes >
            <Route path="/Vendorlogin" element={<>
              <Vendorlogin />
            </>}>
            </Route>
            <Route path="/navbar" element={<>
              <Vedorsnavbar visible={navVisible} show={showNavbar} />
              <Chart />
              <Dashboard />
            </>}>

            </Route>
          </Routes>
        </BrowserRouter>
      )}

    </>
  );
}

export default App;
