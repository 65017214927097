
import her from '../img/imgs/hertshirt.avif';
import him from '../img/imgs/him.jpg';

function herhim(){
    return(
        <div>
<div class="container-fluid my-5">
  <div class="row g-0">
    <div class="col-12 col-lg-6" style={{height:'500px'}}>
     
        <img  style={{width:'100%',height:'100%',objectFit:'cover'}}src={her}></img>
    
    </div>
    <div class="col-12 col-lg-6"  style={{height:'500px'}}>
    <img  style={{width:'100%',height:'100%',objectFit:'cover'}}src={him}></img>
    </div>
  </div>
</div>
        </div>
    )
}
export default herhim;