
import './Vendorcard.css';
import { Link } from 'react-router-dom';

function Vendorcardshome() {
    return (
        <div class="container my-5">
            <div class="row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-xl-5 g-5 g-lg-1 ">
            <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Catogries</p>
                            <i class="bi bi-menu-button mx-auto"></i>
                        </Link>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Carosal Imges</p>
                            <i class="bi bi-card-image mx-auto"></i>
                        </Link>

                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Banner video</p>
                            <i class="bi bi-file-play mx-auto"></i>
                        </Link>
                    </div>
                </div>

                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Offer Imges</p>
                            <i class="bi bi-card-image mx-auto"></i>
                        </Link>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >catogries card</p>
                            <i class="bi bi-card-heading mx-auto"></i>
                        </Link>
                    </div>
                </div>


                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Recent Product</p>
                            <i class="bi bi-cart mx-auto"></i>
                        </Link>
                    </div>
                </div>

                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Available offer</p>
                            <i class="bi bi-gift-fill mx-auto"></i>
                        </Link>
                    </div>
                </div>

                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Future Product</p>
                            <i class="bi bi-cart mx-auto"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Vendorcardshome;