

import '../css/style.css';
import cat1 from '../img/cat-1.jpg';
import mens from '../img/mens.jpg';
import tshirt from '../img/kidstshirt.png';
import kid from '../img/kidstshirt.png';
import women from '../img/womenstshirt.jpeg';
import couple  from '../img/coupletshirt.jpeg';
import group  from '../img/grouptshirt.jpeg';
import offhand from '../img/imgs/offhand.jpeg'
import fullhand from '../img/imgs/fullhandtshirt.avif'
import collor from '../img/imgs/offhand1.webp'
import multicolor from '../img/multicolortshits.jpeg'
import singlecolor from '../img/imgs/singlecolortshirt.png'
import grouptshirt from '../img/imgs/girlsgrouptshirts.png'
import vneck from '../img/imgs/vneck.webp'
import { Link } from 'react-router-dom';


function Categories()
{
    return(
        <div  className="container-fluid pt-5 crd-bg">
{/*        
            <div class="parallax">
            <svg className='parallax-svg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
  <path fill="#F5F5F5" fillOpacity="1" d="M0,288L60,256C120,224,240,160,360,117.3C480,75,600,53,720,74.7C840,96,960,160,1080,192C1200,224,1320,224,1380,224L1440,224L1440,0L1380,0C1320,0,1200,0,1080,0C960,0,840,0,720,0C600,0,480,0,360,0C240,0,120,0,60,0L0,0Z"></path>
</svg>

            </div> */}
             <h1 className='my-5 h1-title-tag our-cat' >Our Catogries</h1>

   
        {/* <h2 className="section-title position-relative text-uppercase mx-xl-5 mb-4"><span className="bg-secondary pr-3">Categories</span></h2> */}
        <div className="row px-xl-5 pb-3">
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item d-flex align-items-center mb-4">
                        <div className="overflow-hidden" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={tshirt} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6 >Kids Tshirt</h6>
                            <small  className=" text-dark">120 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={women} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Womens Tshirts</h6>
                            <small className=" text-dark">More verity collection</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid " style={{width:'100%',height:'100%',objectFit:'cover'}} src={mens} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Mens Tshirt</h6>
                            <small className=" text-dark">200 Products more</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={couple} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Couple Tshirts</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={group} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Group Tshirt</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={multicolor} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Multicolor Tshirt</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={fullhand} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Full_Hand Tshirt</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={offhand} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Off-Hand Tshirt</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={ collor} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Collar Tshirts</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={ vneck} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>V-Neck Tshirts</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={grouptshirt} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Girls Tshirts</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
            <div className="col-lg-3 col-md-4 col-sm-6 pb-1">
                <Link className="text-decoration-none" href="">
                    <div className="cat-item img-zoom d-flex align-items-center mb-4">
                        <div className="overflow-hidden catogrie-img" style={{width: '100px', height: '100px'}}>
                            <img className="img-fluid" src={singlecolor} alt=""/>
                        </div>
                        <div className="flex-fill pl-3">
                            <h6>Plain Tshirts</h6>
                            <small className=" text-dark">100 Products</small>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
    </div>
    )
}
export default Categories;