
import React from 'react';
import "./Vendorlogin.css";
// import { FaCircleUser, RiLockPasswordFill,FaEye, FaEyeSlash,SuccessModal,FailureModal } from "../../imports";


function Vendorlogin(){
    return(
        <div>
        <div class="container-fluid vendLogin-contain">
     <div class="row">
  <div class="col-11 col-lg-4 col-md-6  vendLogin-form">
  <form action="">
        <h1 className='vendLogin-h1'>Login</h1>
        <div class="vendLogin-input-box">
          <input type="text" placeholder="Username" required/>
          <i class="bi bi-person-circle vendLogin-admin-login-icon"></i>
          {/* <FaCircleUser className='admin-login-icon'/> */}
        </div>
        <div class="vendLogin-input-box">
          <input type="password" placeholder="Password" required/>
          <i class="bi bi-eye-fill vendLogin-admin-login-icon"></i>
          {/* <RiLockPasswordFill className='admin-login-icon'/> */}

        </div>
        <div class="vendLogin-remember-forgot">
          {/* <label><input type="checkbox"/></label> */}
          <a href="#">Show Password</a>
        </div>
        <button type="submit" class="vendLogin-btn">Login</button>
        <div class="vendLogin-register-link">
          <p>Dont have an account? <a href="#">Register</a></p>
        </div>
      </form>
  </div>
        </div>     
        </div>
        </div>
    )
}
export default  Vendorlogin;