import { Line } from 'react-chartjs-2';
import { Chart as   ChartJS, CategoryScale, LinearScale, PointElement, LineElement,Legend, Tooltip } from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Legend,
    Tooltip
);

function Chart() {
    const data = {
        labels: ['Jan', 'Feb', 'Mar'],
        datasets: [{
            label: '2024 Sales of the month',
            data: [6, 3, 5],
            backgroundColor: 'black',
            borderColor: '#036bfc',
            pointBorderColor: '#036bfc',
            fill:true,
            tension:0.4,
        }]
    };

    const options = {
        plugins: {
            legend: true
        },
        scales: {
            y: {
                min: 3,
                max: 6
            }
        }
    };

    return (
<>
<div class="container px-4 my-5 " style={{margin:'auto'}}>
  <div class="row gx-5 text-center mx-auto" style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
    <div class="col-12 col-lg-8">
     <div class="mx-auto"> <div style={{padding:'20px'}} >
            <Line
                data={data}
                options={options}
            />
        </div></div>
    </div>
   
  </div>
</div>
       
</>
    
    );
}

export default Chart;
