
import { Link } from "react-router-dom";

function Prdorder(){
    return(
        <div class="container my-5">
        <div class="row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-xl-5 g-5 g-lg-1 ">
        <div class="col text-center">
                <div class="p-3  m-3 ven-card">
                    <Link to="">
                        <p>Payments method</p>
                        <i class="bi bi-menu-button mx-auto"></i>
                    </Link>
                </div>
            </div>  
        </div>
    </div>
    )
}
export default Prdorder;