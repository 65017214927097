 
 
import '../css/style.css'; 

import '../component/Productcard.css';
import mens from '../img/imgs/menmodel.png';
import mens5 from '../img/mens5.avif'
import mens2 from '../img/mens4.webp';
import mens3 from '../img/menstshirt.jpeg'
import { Link } from 'react-router-dom';

 function Recentproduct(){
    return(
      <>
      <div class="container">
      <h1 className='my-5 h1-title-tag'>Mens tshirts</h1>
  <div class="row row-cols-1 row-cols-lg-4 row-cols-md-3 g-2 g-lg-3">
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link to=''><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to="/Productdetail"><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="bi bi-cart-check-fill"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Buy Now </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
  
  </div>
</div>
      </>
    )
};
export default Recentproduct;