

import './Adminsidebar.css';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdMenu } from 'react-icons/md';
import { FaHome } from 'react-icons/fa';
import { FaHouseUser } from "react-icons/fa";
import { MdSupervisedUserCircle } from "react-icons/md";
import { FaSignOutAlt, FaAngleRight, FaAngleLeft, FaBars } from "react-icons/fa";
import { FaUserCheck } from "react-icons/fa";
import { FaUserLock } from "react-icons/fa6";
import { IoDuplicateSharp } from "react-icons/io5";
// import { useAdminAuth } from '../AdminAuthProvider';
// import {MdOutlineSmartphone} from "../../../imports";
const ICON_SIZE = 20;

function Vendoradmin({ visible, show }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isVendorsOpen, setIsVendorsOpen] = useState(false);
  //  const {adminLogout}=useAdminAuth();


  const toggleVendors = () => {
    setIsVendorsOpen(!isVendorsOpen);
  };
  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };
  return (
    <>
      <div className="mobile-nav">
        <button
          className="mobile-nav-btn"
          onClick={() => show(!visible)}
        >
          <FaBars size={24} />
        </button>
      </div>


      <div  className=" nav-div ${!visible ? 'nav-visiblenavbar' : ''}">
        <button
          type="submit"
          className="admin-nav-btn-angle-icon"
          // style={{backgroundColor:"#2653c6"}}
          onClick={() => show(!visible)}
        >
          {!visible
            ? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
        </button>
        <div>
          <Link
            className="logo"
            to="/"
          >
         
          </Link>
          <div className="links nav-top">
            <Link to="/admin/dashboard" className="admin-nav-link">
              <FaHome size={ICON_SIZE} className='admin-nav-icon' />
              <span>Dashboard</span>
            </Link>

            <Link to="/admin/master/category" className="admin-nav-link">
              <IoDuplicateSharp  size={ICON_SIZE} className='admin-nav-icon' />
              <span>Master</span>
            </Link>
            <Link to="#" className="admin-nav-link" onClick={toggleVendors}>
              <FaHouseUser size={ICON_SIZE} className='admin-nav-icon' />
              <span>Vendors</span>
            </Link>
         
            <Link className="admin-nav-link" onClick={toggleDropdown}>
              <MdSupervisedUserCircle size={ICON_SIZE} className='admin-nav-icon' />
              <span>Users</span>
            </Link>
            {dropdownVisible && (
              <div className="dropdown-content">
                <li>
                  <Link to="#" className="admin-nav-link-li">
                    <MdSupervisedUserCircle size={ICON_SIZE} className='admin-nav-icon-span' />
                    <span>Item 1</span>
                  </Link>
                </li>
                <li>
                  <Link to="#" className="admin-nav-link-li">
                    <MdSupervisedUserCircle size={ICON_SIZE} className='admin-nav-icon-span' />
                    <span>Item 2</span>
                  </Link>
                </li>
                {/* Add more items as needed */}
              </div>
            )}
   <Link  to ="/admin/callrequest" className="admin-nav-link">
              {/* <MdOutlineSmartphone size={ICON_SIZE} className='admin-nav-icon' /> */}
              <span>Call Request</span>
            </Link>
            <Link to="/admin/login" className="admin-nav-link">
              <FaSignOutAlt size={ICON_SIZE} className='admin-nav-icon' />
              <span>Logout</span>
            </Link>


          </div>
        </div>






       
      </div>
    </>
  )
}

export default Vendoradmin;