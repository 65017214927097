
import { Link } from "react-router-dom";

function Dashboard(){
    return(
        <div class="container my-5 ">
        <div class="row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-xl- g-5 g-lg-1 ">
        <div class="col text-center">
                <Link to="" style={{textDecoration: 'none' }}>
                <div class="p-3  m-3 ven-card d-block">
                <p>Order Products</p>
                        <i class="bi bi-shop mx-auto"></i>
                </div>
                </Link>
            </div>
            
           
            <div class="col text-center">
       <Link to="" style={{textDecoration: 'none' }}>
       <div class="p-3  m-3 ven-card d-block">
       <p>Customers</p>
               <i class="bi bi-person-fill mx-auto"></i>
       </div>
       </Link>
   </div>
         

   <div class="col text-center">
       
      
               
               
       <Link to="" style={{textDecoration: 'none' }}>
       <div class="p-3  m-3 ven-card d-block">
       <p>Messages</p>
               <i class="bi bi-chat-left-dots-fill mx-auto"></i>
       </div>
       </Link>
   </div>
          
   <div class="col text-center">
     <Link to="" style={{textDecoration: 'none'}}>
       <div class="p-3  m-3 ven-card d-block">
       <p>Delivery product</p>
               <i class="bi bi-truck mx-auto"></i>
       </div>
       </Link>
   </div>
          

          

           

        </div>
    </div>
    )
}
export default Dashboard;