
import '../css/style.css'; 
import offer1 from '../img/offer-1.jpg';
import offer2 from '../img/offer-2.jpg';
import offershop from '../img/imgs/offershop.jpg'
import ladytshirt from '../img/imgs/ladytshirt.jpg'
import { Link } from 'react-router-dom';

function Offer(){
    return(
        <div>
             <div className="container-fluid pt-5 pb-3">
             <h1 className='my-5 h1-title-tag'>Our Available&nbsp;<span >Offer</span></h1>
        <div className="row px-xl-5">
            <div className="col-md-6">
                <div className="product-offer mb-30" style={{height: '300px'}}>
                    <img className="img-fluid" src={offershop} alt=""/>
                    <div className="offer-text">
                        <h6 className="text-white text-uppercase">Save 50%</h6>
                        <h3 className="text-white mb-3">Summer Offer</h3>
                        <Link to='/Offerproduct' className="btn btn-primary">Shop Now</Link>
                    </div>
                </div>
            </div>
            <div className="col-md-6">
                <div className="product-offer mb-30"  style={{height: '300px'}}>
                    <img className="img-fluid" src={ladytshirt} alt=""/>
                    <div className="offer-text">
                        <h6 className="text-white text-uppercase"> use coupen code Save 20%</h6>
                        <h3 className="text-white mb-3"> "5#@45L-Krt"</h3>
                        <Link to='/Offerproduct' className="btn btn-primary">Shopnow</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
        </div>
    )
}
export default Offer;