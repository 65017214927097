
import { Link } from 'react-router-dom';
import '../css/style.css';
// import { Link } from 'react-router-dom';
import logo from '../img/imgs/logo.png'

function Subheader(){
    return(
        <div className="container-fluid bg-dark mb-30">
        <div className="row px-xl-5">
            <div className="col-lg-3  d-lg-block">
                <span className="btn d-flex align-items-center justify-content-between bg-primary w-100" data-toggle="collapse" href="#navbar-vertical" style={{height: '65px', padding: '0 30px'}}>
                    <h6 className="text-dark m-0"><i className="fa fa-bars mr-2"></i>Our Product</h6>
                    <i className="fa fa-angle-down text-dark"></i>
                </span>
                <nav className="collapse position-absolute navbar navbar-vertical navbar-light align-items-start p-0 bg-light" id="navbar-vertical" style={{width:' calc(100% - 30px)',zIndex: '999'}}>
                    <div className="navbar-nav w-100">
                        <div className="nav-item dropdown dropright">
                            <a to="" className="nav-link dropdown-toggle" data-toggle="dropdown">Mens <i className="fa fa-angle-right float-right mt-1"></i></a>
                            <div className="dropdown-menu position-absolute rounded-0 border-0 m-0">
                                <Link to="/filter" className="dropdown-item">V-neck Tshirt</Link>
                                <Link to="/filter" className="dropdown-item">Collor Tshirt</Link>
                                <Link to="/filter" className="dropdown-item">Full Hand Tshirt </Link>
                                <Link to="/filter" className="dropdown-item">Round Neck Tshirt</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown dropright">
                            <a to="" className="nav-link dropdown-toggle" data-toggle="dropdown">Kids<i className="fa fa-angle-right float-right mt-1"></i></a>
                            <div className="dropdown-menu position-absolute rounded-0 border-0 m-0">
                            <Link to="" className="dropdown-item">V-neck Tshirt</Link>
                                <Link to="" className="dropdown-item">Collor Tshirt</Link>
                                <Link to="" className="dropdown-item">Full Hand Tshirt </Link>
                                <Link to="" className="dropdown-item">Round Neck Tshirt</Link>
                            </div>
                        </div>
                        <div className="nav-item dropdown dropright">
                            <a to="" className="nav-link dropdown-toggle" data-toggle="dropdown">Womens<i className="fa fa-angle-right float-right mt-1"></i></a>
                            <div className="dropdown-menu position-absolute rounded-0 border-0 m-0">
                            <Link to="" className="dropdown-item">V-neck Tshirt</Link>
                                <Link to="" className="dropdown-item">Collor Tshirt</Link>
                                <Link to="" className="dropdown-item">Full Hand Tshirt </Link>
                                <Link to="" className="dropdown-item">Round Neck Tshirt</Link>
                            </div>
                        </div>
                        {/* <Link to="" className="nav-item nav-link">Shirts</Link>
                        <Link to="" className="nav-item nav-link">Jeans</Link>
                        <Link to="" className="nav-item nav-link">Swimwear</Link>
                        <Link to="" className="nav-item nav-link">Sleepwear</Link>
                        <Link to="" className="nav-item nav-link">Sportswear</Link>
                        <Link to="" className="nav-item nav-link">Jumpsuits</Link>
                        <Link to="" className="nav-item nav-link">Blazers</Link>
                        <Link to="" className="nav-item nav-link">Jackets</Link>
                        <Link to="" className="nav-item nav-link">Shoes</Link> */}
                    </div>
                </nav>
            </div>
            <div className="col-lg-9">
                <nav className="navbar navbar-expand-lg bg-dark navbar-dark py-3 py-lg-0 px-0">
                    <Link to="" className="text-decoration-none d-block d-lg-none">
                    <img style={{width:'50px',height:'50px',objectFit:'contain',backgroundColor:'white'}} src={ logo}></img>
                        {/* <span className="h1 text-uppercase text-dark bg-light px-2">Multi</span>
                        <span className="h1 text-uppercase text-light bg-primary px-2 ml-n1">Shop</span> */}
                    </Link>
                    <button type="button" className="navbar-toggler" data-toggle="collapse" data-target="#navbarCollapse">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse justify-content-between" id="navbarCollapse">
                        <div className="navbar-nav mr-auto py-0">
                            <Link to='/' className="nav-item nav-link active">Home</Link>
                            {/* <Link to='' className="nav-item nav-link">FAQs</Link> */}
                            {/* <Link href="detail.html" className="nav-item nav-link">About</Link> */}
                            {/* <div className="nav-item dropdown">
                                <a to="" className="nav-link dropdown-toggle" data-toggle="dropdown">Pages <i className="fa fa-angle-down mt-1"></i></a>
                                <div className="dropdown-menu bg-primary rounded-0 border-0 m-0">
                                    <Link href="cart.html" className="dropdown-item">Shopping Cart</Link>
                                    <Link href="checkout.html" className="dropdown-item">Checkout</Link>
                                </div>
                            </div> */}
                            <Link to='/contact' className="nav-item nav-link">Contact</Link>
                        </div>
                        <div className="navbar-nav ml-auto py-0 d-none d-lg-block">
                            {/* <Link to="" className="btn px-0">
                                <i className="fas fa-heart text-primary"></i>
                                <span className="badge text-secondary border border-secondary rounded-circle" style={{paddingBottom: '2px',margin:'5px'}}>5</span>
                            </Link> */}
                            <Link to="/addtocard" className="btn px-0 ml-3">
                                <i className="fas fa-shopping-cart text-primary"></i>
                                <span className="badge text-secondary border border-secondary rounded-circle" style={{paddingBottom: '2px',margin:'5px'}}>5</span>
                            </Link>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
    )
}
export default Subheader;