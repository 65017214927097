
import girlkid from "../img/imgs/girlkidimg.jpg"
import boykid from '../img/kid.jpg'
import kidoffer from '../img/imgs/kidoffer.jpg'

function Kids(){
    return(
        <div className="my-5">
            <div class="container-fluid">
  <div class="row g-0">
    <div class="col-12 col-lg-6" style={{height:'500px'}}>
     
        <img  style={{width:'100%',height:'100%',objectFit:'cover'}}src={girlkid}></img>
    
    </div>
    <div class="col-12 col-lg-6"  style={{height:'500px'}}>
    <img  style={{width:'100%',height:'100%',objectFit:'cover'}}src={boykid}></img>
    </div>
  </div>
</div>


        </div>
    )
}
export default Kids;