
import './Header1.css';
import logo from '../img/imgs/logo.png'
import { Link } from 'react-router-dom';
import Scrollcatogries from './Scrollcatogries';
import Icons from './Icons';
function Header1() {
   return (
      <div className='sticky-head'>
         <div className='container-fluid bg-dark py-2 '>
            <div className='top-hed'>
               <Link to='/contact' className='top-hed-lnk mx-2'>Contact</Link>
               <Link to='/' className='top-hed-lnk mx-2'>FAQs</Link>
               <Link to='/Visiting' className='top-hed-lnk mx-2'style={{float:'right'}}><i class="bi bi-heart-fill"></i> <span className="badge  border border-white rounded-circle" style={{paddingBottom: '2px',margin:'5px',color:'white'}}>5</span></Link>
               <Link to='/addtocard' className='top-hed-lnk mx-2' style={{float:'right'}}><i class="bi bi-cart-check-fill"></i> <span className="badge  border border-white rounded-circle" style={{paddingBottom: '2px',margin:'5px',color:'white'}}>5</span></Link>
            </div>
         </div>
         <header class="header">
            <div class="head-container">
               <div class="hed-row head-align-items-center head-justify-content-between">
                  <div class="head-logo">
                     <img style={{ height: '50px', width: '100px', objectFit: 'contain' }} src={logo}></img>
                  </div>
                  <input type="checkbox" id="nav-check" />
                  <label for="nav-check" class="nav-toggler">
                     <span></span>
                  </label>
                  <nav class="nav">
                     <div className='ul-div'>
                        <li><Link to="/" class="active">home</Link></li>
                        {/* <li><a href=""></a></li> */}
                        <li><Link to="/Vendorlogin">Login</Link></li>
                        <li><Link to="/contact">Contact</Link></li>
                        {/* <li><Link href="/addtocard">
                  
                 <i class="bi bi-cart2" style={{color:'black',fontSize:'20px'}}></i>
                    </Link></li> */}
                        {/* <li><Link href="/addtocard">
                 <i class="bi bi-heart-fill" style={{color:'black',fontSize:'20px'}}></i>
                    </Link></li> */}

                        {/* <li><a href="#">portfolio</a></li>
                 <li><a href="#">testimonials</a></li>
                 <li><a href="#">contact</a></li> */}
                     </div>
                  </nav>
               </div>

            </div>

         </header>
         <Scrollcatogries />
      </div>
   )
}
export default Header1;