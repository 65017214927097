
import { Link } from "react-router-dom";
import mens from '../img/imgs/menmodel.png';
import mens5 from '../img/mens5.avif'
import mens2 from '../img/mens4.webp';
import mens3 from '../img/menstshirt.jpeg'


function Offerproduct(){
    return(
        <div>
       <div class="container-fluid my-5">
        <div class="row px-xl-5">
           
            <div class="col-lg-3 col-md-4">
               
                <h5 class="section-title position-relative text-uppercase mb-3"><span class="bg-secondary pr-3">Filter by price</span></h5>
                <div class="bg-light p-4 mb-30">
                    <form>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" checked id="price-all"/>
                            <label class="custom-control-label" for="price-all">All Price</label>
                            <span class="badge border font-weight-normal">1000</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="price-1"/>
                            <label class="custom-control-label" for="price-1">$0 - $100</label>
                            <span class="badge border font-weight-normal">150</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="price-2"/>
                            <label class="custom-control-label" for="price-2">$100 - $200</label>
                            <span class="badge border font-weight-normal">295</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="price-3"/>
                            <label class="custom-control-label" for="price-3">$200 - $300</label>
                            <span class="badge border font-weight-normal">246</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="price-4"/>
                            <label class="custom-control-label" for="price-4">$300 - $400</label>
                            <span class="badge border font-weight-normal">145</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between">
                            <input type="checkbox" class="custom-control-input" id="price-5"/>
                            <label class="custom-control-label" for="price-5">$400 - $500</label>
                            <span class="badge border font-weight-normal">168</span>
                        </div>
                    </form>
                </div>
            
                
                
                <h5 class="section-title position-relative text-uppercase mb-3"><span class="bg-secondary pr-3">Filter by color</span></h5>
                <div class="bg-light p-4 mb-30">
                    <form>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" checked id="color-all"/>
                            <label class="custom-control-label" for="price-all">All Color</label>
                            <span class="badge border font-weight-normal">1000</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="color-1"/>
                            <label class="custom-control-label" for="color-1">Black</label>
                            <span class="badge border font-weight-normal">150</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="color-2"/>
                            <label class="custom-control-label" for="color-2">White</label>
                            <span class="badge border font-weight-normal">295</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="color-3"/>
                            <label class="custom-control-label" for="color-3">Red</label>
                            <span class="badge border font-weight-normal">246</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="color-4"/>
                            <label class="custom-control-label" for="color-4">Blue</label>
                            <span class="badge border font-weight-normal">145</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between">
                            <input type="checkbox" class="custom-control-input" id="color-5"/>
                            <label class="custom-control-label" for="color-5">Green</label>
                            <span class="badge border font-weight-normal">168</span>
                        </div>
                    </form>
                </div>
               

               
                <h5 class="section-title position-relative text-uppercase mb-3"><span class="bg-secondary pr-3">Filter by size</span></h5>
                <div class="bg-light p-4 mb-30">
                    <form>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" checked id="size-all"/>
                            <label class="custom-control-label" for="size-all">All Size</label>
                            <span class="badge border font-weight-normal">1000</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="size-1"/>
                            <label class="custom-control-label" for="size-1">XS</label>
                            <span class="badge border font-weight-normal">150</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="size-2"/>
                            <label class="custom-control-label" for="size-2">S</label>
                            <span class="badge border font-weight-normal">295</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="size-3"/>
                            <label class="custom-control-label" for="size-3">M</label>
                            <span class="badge border font-weight-normal">246</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between mb-3">
                            <input type="checkbox" class="custom-control-input" id="size-4"/>
                            <label class="custom-control-label" for="size-4">L</label>
                            <span class="badge border font-weight-normal">145</span>
                        </div>
                        <div class="custom-control custom-checkbox d-flex align-items-center justify-content-between">
                            <input type="checkbox" class="custom-control-input" id="size-5"/>
                            <label class="custom-control-label" for="size-5">XL</label>
                            <span class="badge border font-weight-normal">168</span>
                        </div>
                    </form>
                </div>
            </div>
            <div class="col-lg-9 col-md-8">
            <div class="container">
      <h1 className='my-5 h1-title-tag'>Offer Product</h1>
  <div class="row row-cols-1 row-cols-lg-3 row-cols-md-2 g-2 g-lg-3">
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to="/Productdetail"><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={mens}/>
                    <img class="pic-2" src={mens2}/>
                </Link>
                <span class="product-discount-label">-40%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">V-neck tshirt</a></h3>
                <div class="price"><span>Rs.400</span>Rs.200</div>
            </div>
        </div>
    </div>

   
    <div class="col">
      <div class="product-grid my-4">
            <div class="product-image  ">
                <Link style={{ textdecoration: 'none'}} to='/Productdetail' class="image">
                    <img class="pic-1" src={ mens2}/>
                    <img class="pic-2" src={mens3}/>
                </Link>
                <span class="product-discount-label">-20%</span>
                <ul class="product-links">
                    <li><Link href="#"><i class="bi bi-heart-fill"></i></Link></li>
                    <li><Link href="#"><i class="fa fa-random"></i></Link></li>
                </ul>
                <Link class="add-cart" to='/Productdetail'><i class="fas fa-shopping-cart"></i> Add to cart </Link>
            </div>
            <div class="product-content">
                <h3 class="title"><a href="#">collor tshirt</a></h3>
                <div class="price"><span>Rs:200</span> Rs:150</div>
            </div>
        </div>
    </div>
  
  </div>
</div>
            <div class="col-12">
                        <nav>
                          <ul class="pagination justify-content-center">
                            <li class="page-item disabled"><a class="page-link" href="#"><span>Previous</span></a></li>
                            <li class="page-item active"><a class="page-link" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item"><a class="page-link" href="#">Next</a></li>
                          </ul>
                        </nav>
                    </div>
            </div>
           
        </div>
    </div>
            </div>
    )
}
export default Offerproduct;