

import { Link } from "react-router-dom";

function Vendorfilter(){
    return(
        <div class="container my-5">
            <div class="row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-xl-5 g-5 g-lg-1 ">
            <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p>Color</p>
                            <i class="bi bi-paint-bucket mx-auto"></i>
                        </Link>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Size</p>
                            <i class="bi bi-aspect-ratio mx-auto"></i>
                        </Link>

                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Price</p>
                            <i class="bi bi-tag mx-auto"></i>
                        </Link>
                    </div>
                </div>

                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Catogries title</p>
                            <i class="bi bi-card-heading  mx-auto"></i>
                        </Link>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >product img </p>
                            <i class="bi bi-person-vcard mx-auto"></i>
                        </Link>
                    </div>
                </div>

                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >product price </p>
                            <i class="bi bi-tag mx-auto"></i>
                        </Link>
                    </div>
                </div>

                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >offers </p>
                            <i class="bi bi-gift mx-auto"></i>
                        </Link>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Product name </p>
                            <i class="bi bi-fonts mx-auto"></i>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Vendorfilter;