


import mens from '../img/mens.jpg';
import './Scrollcatogries.css';
import { Link } from 'react-router-dom';
import tshirt from '../img/kidstshirt.png';
import kid from '../img/kidstshirt.png';
import women from '../img/womenstshirt.jpeg';
import couple  from '../img/coupletshirt.jpeg';
import group  from '../img/grouptshirt.jpeg';
import offhand from '../img/imgs/offhand.jpeg'
import fullhand from '../img/imgs/fullhandtshirt.avif'
import collor from '../img/imgs/offhand1.webp'
import multicolor from '../img/multicolortshits.jpeg'
import singlecolor from '../img/imgs/singlecolortshirt.png'
import grouptshirt from '../img/imgs/girlsgrouptshirts.png'
import vneck from '../img/imgs/vneck.webp'
import her from '../img/imgs/hertshirt.avif';

function Scrollcatogries(){
    return(
        <div className="container-fluid cat-scroll py-3 mb-5 d-flex ">
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={mens} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Mens Tshirt</p>
            </div>
           </Link> 
            
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={women} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Girls Tshirt</p>
            </div>
           </Link> 

           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={vneck} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Boys Tshirt</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={grouptshirt} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Women Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={kid} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Kids Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={mens} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Bays Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={mens} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Mens Tshirt</p>
            </div>
           </Link> 
            
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={women} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Girls Tshirt</p>
            </div>
           </Link> 

           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={vneck} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Boys Tshirt</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={grouptshirt} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Women Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={kid} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Kids Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={mens} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Bays Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={mens} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Mens Tshirt</p>
            </div>
           </Link> 
            
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={women} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Girls Tshirt</p>
            </div>
           </Link> 

           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={vneck} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Boys Tshirt</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={grouptshirt} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Women Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={kid} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Kids Tshirts</p>
            </div>
           </Link> 
           <Link style={{textDecoration:'none',color:'inherit'}} to='/filter'>
           <div className='cat-common-div'>
            <div className='cat-scroll-img-div  mx-auto '>
            <img src={mens} ></img>
            </div>
            <p className='text-center cat-scroll-p'>Bays Tshirts</p>
            </div>
           </Link> 
            </div>
    )
}
export default Scrollcatogries;