

import { Link } from "react-router-dom";

function Vendorproductdetails(){
    return(
        <div class="container my-5">
            <div class="row row-cols-1 row-cols-lg-4 row-cols-md-3 row-cols-xl-5 g-5 g-lg-1 ">
          
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Product Imges</p>
                            <i class="bi bi-card-image mx-auto"></i>
                        </Link>

                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Description</p>
                            <i class="bi bi-body-text mx-auto"></i>
                        </Link>
                    </div>
                </div>

                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Information</p>
                            <i class="bi bi-info-lg mx-auto"></i>
                        </Link>
                    </div>
                </div>
                <div class="col text-center">
                    <div class="p-3  m-3 ven-card">
                        <Link to="">
                            <p >Reviews</p>
                            <i class="bi bi-star-fill mx-auto"></i>
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Vendorproductdetails;
