

// import { Link } from "react-router-dom";
import '../css/style.css';
function Backtop(){
    return(
        <div>
             <button  className="btn btn-primary back-to-top"><i className="fa fa-angle-double-up"></i></button>
        </div>
    )
}
export default Backtop;