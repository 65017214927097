import React from 'react';
import {
	FaAngleRight,
	FaAngleLeft, 
	FaChartBar, 
	FaThLarge, 
	FaShoppingCart, 
	FaCog,
	FaSignOutAlt,
	FaBars
} from 'react-icons/fa';
import { NavLink } from "react-router-dom";
// import "../style/navbar.css";
import './Vendsidebar.css';
// import Logo from '../../img/imgs/logo.png';

const ICON_SIZE = 20;

function Vedorsnavbar({visible, show}) {

	return (
		<>
			<div className="vend-mobile-nav">
				<button
				style={{outline:'none',border:"none"}}
					className="vend-mobile-nav-btn"
					onClick={() => show(!visible)}
				>
					<FaBars size={24}  />
				</button>
			</div>
			<div className={`nav-div ${!visible ? 'vend-navbar' : ''}`}>
				<button
				style={{outline:'none',border:"none"}}
					type="button"
					className="vend-nav-btn"
					onClick={() => show(!visible)}
				>
					{ !visible
						? <FaAngleRight size={30} /> : <FaAngleLeft size={30} />}
				</button>
				<div>
					<NavLink
						className="logo"
						to="/"
					>
							{/* <img
								src={Logo}
								alt="logo"
							/> */}
					</NavLink>
					<div className="links vend-nav-top">
						<NavLink to="/dashboard" className="vend-nav-link">
							<FaThLarge size={ICON_SIZE} />
							<span>Dashboard</span>
						</NavLink>
						<NavLink to="/analytics" className="vend-nav-link">
							<FaChartBar size={ICON_SIZE} />
							<span>Home</span>
						</NavLink>
						<NavLink to="/orders" className="vend-nav-link">
							<FaShoppingCart size={ICON_SIZE} />
							<span>Filter</span> 
						</NavLink>
						<NavLink to="/orders" className="vend-nav-link">
							<FaShoppingCart size={ICON_SIZE} />
							<span>Product Details</span> 
						</NavLink>
						<NavLink to="/orders" className="vend-nav-link">
							<FaShoppingCart size={ICON_SIZE} />
							<span>Order</span> 
						</NavLink>
						<NavLink to="/orders" className="vend-nav-link">
							<FaShoppingCart size={ICON_SIZE} />
							<span>Add to cart</span> 
						</NavLink>
					</div>
				</div>

				<div className="links">
					<NavLink to="/settings" className="vend-nav-link">
						<FaCog size={ICON_SIZE} />
						<span>Settings</span> 
					</NavLink>
					<NavLink to="/Sign-out" className="vend-nav-link">
						<FaSignOutAlt size={ICON_SIZE} />
						<span>Logout</span> 
					</NavLink>
				</div>
			</div>
		</>
  );
}

export default Vedorsnavbar;
